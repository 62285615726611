import { useToast } from '@capturi/ui-components'
import {
  ActionMeta,
  OnChangeValue,
  Select,
  SelectOption,
} from '@capturi/ui-select'
import { Flex, FormControl, FormLabel, IconButton } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React from 'react'
import { MdCopyAll } from 'react-icons/md'

type Props = {
  options: string[]
  value: string[]
  onChange: (fields: string[]) => void
  isDisabled: boolean
}

const CustomFieldSelector: React.FC<Props> = ({
  options: customProps = [],
  value = [],
  onChange,
  isDisabled,
}) => {
  const toast = useToast()

  const options = customProps.map((c) => ({ value: c, label: c }))

  const initialValue = value.map((c) => ({
    value: c,
    label: c,
  }))

  const handleChange = (
    values: OnChangeValue<SelectOption, boolean>,
    action: ActionMeta<SelectOption>,
  ): void => {
    if (
      ['select-option', 'remove-value', 'deselect-option'].includes(
        action.action,
      ) &&
      Array.isArray(values)
    ) {
      onChange(values.map((x) => x.value))
    }
  }
  const handleCopy = (): void => {
    navigator.clipboard.writeText(options.join('\n'))
    toast({ status: 'info', title: t`Copied custom fields list to clipboard` })
  }

  return (
    <FormControl>
      <Flex justifyContent="space-between">
        <FormLabel>
          <Trans>Custom Fields (max 10)</Trans>
        </FormLabel>
        <IconButton
          variant="ghost"
          icon={<MdCopyAll />}
          onClick={handleCopy}
          aria-label="copy list content"
        />
      </Flex>
      <Select
        isOptionDisabled={() => options.length >= 10}
        isDisabled={isDisabled}
        defaultValue={initialValue}
        isMulti
        options={options}
        placeholder={t`Select custom fields ...`}
        onChange={handleChange}
        menuPortalTarget={document.body}
        menuPosition="fixed"
        hideSelectedOptions={false}
        backspaceRemovesValue
        isClearable={false}
        autoFocus={false}
        minMenuHeight={100}
        maxMenuHeight={612}
      />
    </FormControl>
  )
}

export default CustomFieldSelector

import { Card, Spinner } from '@capturi/ui-components'
import { Flex, Heading, Text } from '@chakra-ui/react'
import React from 'react'
import { type TextImporterConfig, useTextImporterSchema } from '../useConfig'
import { IntegrationConfig } from './IntegrationConfig'

type Props = {
  integrationUid: string
  integrationType: string
  url: string
  config: TextImporterConfig
}

export const DataLoaderContainer: React.FC<Props> = ({
  integrationUid,
  integrationType,
  url,
  config,
}) => {
  const schema = useTextImporterSchema(url, integrationUid)
  // const config = useTextImporterConfig(url, integrationUid)

  const isLoading = schema.isLoading
  const isError = schema.isError
  // const isLoading = config.isLoading || schema.isLoading
  // const isError = config.isError || schema.isError

  return (
    <Card p="4" key={integrationUid}>
      <Flex mb="8">
        <Heading>{integrationType} Importer Configuration</Heading>
      </Flex>
      {isLoading && <Spinner size="xl" />}

      {isError && (
        <Flex justifyContent="center" alignItems="center" minH="xl">
          {schema.isError && (
            <Text fontSize="xl" color="danger">
              {schema.error.message}
            </Text>
          )}
        </Flex>
      )}

      {schema.data && config && (
        <IntegrationConfig
          integrationType={integrationType}
          integrationKey={integrationUid}
          schema={schema.data}
          currentIntegrationOptions={config.options || {}}
          currentCustomFields={config.customFields}
          currentContinuationToken={config.continuationToken}
          currentFilters={config.filters}
          isRunning={config.isActive}
        />
      )}
    </Card>
  )
}

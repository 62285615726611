import analytics from '@capturi/analytics'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@capturi/ui-components'
import { Flex, FormControl, FormLabel, Input, Stack } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'

import { useCreateTeam, useUpdateTeam } from '../../hooks/useTeams'

type Props = {
  uid?: string
  name?: string
  onClose: () => void
}

const CreateUpdateTeam: React.FC<Props> = ({
  uid,
  name: originalName,
  onClose,
}) => {
  const { mutate: createTeam, isPending: isTeamCreating } = useCreateTeam()
  const { mutate: updateTeam, isPending: isTeamUpdating } = useUpdateTeam()
  const toast = useToast()

  const nameRef = useRef<HTMLInputElement>(null)

  const [name, setName] = useState(originalName || '')

  useEffect(() => {
    if (uid) {
      analytics.modalview('updateTeam')
      analytics.event('organization_teams_openUpdate')
    } else {
      analytics.modalview('createTeam')
      analytics.event('organization_teams_openCreate')
    }
  }, [uid])

  const handleSubmit = (): void => {
    if (uid) {
      updateTeam(
        { uid: uid, name: name },
        {
          onSuccess: () => {
            toast({ title: t`Team updated`, status: 'success' })
            onClose()
          },
          onError: () => {
            toast({
              title: t`Failed to update team. Please try again.`,
              status: 'error',
            })
          },
        },
      )
      analytics.event('organization_teams_update')
    } else {
      createTeam(name, {
        onSuccess: () => {
          toast({ title: t`Team created`, status: 'success' })
          onClose()
        },
        onError: () => {
          toast({
            title: t`Failed to create team. Please try again.`,
            status: 'error',
          })
          onClose()
        },
      })
    }
  }

  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      initialFocusRef={nameRef}
      closeOnEsc={!isTeamCreating && !isTeamUpdating}
    >
      <ModalOverlay>
        <ModalContent>
          <Flex direction="column">
            <ModalHeader>
              {uid ? <Trans>Edit team</Trans> : <Trans>Add team</Trans>}
            </ModalHeader>
          </Flex>
          {!isTeamCreating && !isTeamUpdating && <ModalCloseButton />}
          <ModalBody>
            <FormControl>
              <FormLabel htmlFor="name">
                <Trans>Name</Trans>
              </FormLabel>
              <Input
                id="name"
                ref={nameRef}
                onChange={(e: ChangeEvent<HTMLInputElement>): void =>
                  setName(e.target.value)
                }
                value={name}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter
            justifyContent="space-between"
            flexDirection="row-reverse"
          >
            <Stack isInline spacing={4}>
              <Button
                secondary
                isDisabled={isTeamCreating || isTeamUpdating}
                onClick={onClose}
              >
                <Trans>Cancel</Trans>
              </Button>
              <Button
                primary
                isLoading={isTeamCreating || isTeamUpdating}
                onClick={handleSubmit}
                isDisabled={!name || originalName === name}
              >
                {uid ? <Trans>Save</Trans> : <Trans>Create</Trans>}
              </Button>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}

export default CreateUpdateTeam

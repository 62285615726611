import Icon_EmptyState from '@capturi/assets/images/EmptyState.svg'
import { Button, ContentPlaceholder, Spinner } from '@capturi/ui-components'
import { Box, Table, Tbody, Text, Th, Thead, Tr } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React from 'react'
import { MdAdd } from 'react-icons/md'

import { useFeatureFlags } from '@capturi/feature-flags'
import { useModal } from '@capturi/use-modal'
import { TableWrapper, TableWrapperHeader } from '../../components/TableWrapper'
import { useTeams } from '../../hooks/useTeams'
import CreateUpdateTeam from './CreateUpdateTeam'
import TeamsTableRow from './TeamsTableRow'

const Teams: React.FC = () => {
  const { disableUserConfig } = useFeatureFlags()
  const { teams, isPending } = useTeams()

  const [openCreateUpdateTeam] = useModal(CreateUpdateTeam)

  if (isPending) {
    return <Spinner />
  }

  if (teams.length === 0) {
    return (
      <Box>
        <NoTeamsPlaceholder onAddTeam={() => openCreateUpdateTeam()} />
      </Box>
    )
  }

  return (
    <Box width="full" overflowX="auto">
      <TableWrapper>
        <TableWrapperHeader
          title={t`Teams`}
          tooltip={t`Use teams to group your agents, making it easier and faster to filter and analyse data for a given team.`}
        >
          {!disableUserConfig && (
            <Button
              primary
              onClick={() => openCreateUpdateTeam()}
              size="sm"
              float="right"
              my={2}
            >
              <Trans>Add team</Trans>
            </Button>
          )}
        </TableWrapperHeader>
        <Table variant="bordered">
          <Thead>
            <Tr>
              <Th>
                <Trans>Team</Trans>
              </Th>
              <Th>
                <Trans>Members</Trans>
              </Th>
              <Th w="5%" />
            </Tr>
          </Thead>
          <Tbody>
            {teams.map((team) => (
              <TeamsTableRow
                key={team.uid}
                onEdit={openCreateUpdateTeam}
                {...team}
              />
            ))}
          </Tbody>
        </Table>
      </TableWrapper>
    </Box>
  )
}

export default Teams

const NoTeamsPlaceholder: React.FC<{
  onAddTeam: () => void
}> = ({ onAddTeam }) => {
  return (
    <ContentPlaceholder.Container mt="10vh">
      <ContentPlaceholder.Image as={Icon_EmptyState} />
      <ContentPlaceholder.Heading>
        <Trans>It&apos;s a bit empty here!</Trans>
      </ContentPlaceholder.Heading>
      <ContentPlaceholder.Body>
        <Text>
          <Trans>Use teams to filter your conversations.</Trans>
        </Text>
        <Text mt={4}>
          <Trans>
            It is especially useful when you have multiple departments or teams
            who have different goals that you would like to filter upon.
          </Trans>
        </Text>
      </ContentPlaceholder.Body>
      <ContentPlaceholder.Footer>
        <Button primary onClick={onAddTeam} leftIcon={<MdAdd />}>
          <Trans>Add team</Trans>
        </Button>
      </ContentPlaceholder.Footer>
    </ContentPlaceholder.Container>
  )
}

import { DatePicker } from '@capturi/ui-components'
import { BaseModalComponentProps } from '@capturi/use-modal'
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useToast,
} from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { Trans, t } from '@lingui/macro'
import { endOfDay } from 'date-fns'
import React, { useState } from 'react'
import { DeleteTeamPayload, useMarkTeamForDeletion } from '../../hooks/useTeams'

type Props = { teamName: string; teamUid: string } & BaseModalComponentProps

export const DeleteTeamModal: React.FC<Props> = ({
  onClose,
  teamName,
  teamUid,
}) => {
  const [toggleDatePicker, setToggleDatePicker] = useState<boolean>(false)
  const [date, setDate] = useState<Date>(endOfDay(new Date()))
  const { mutate: deleteTeam } = useMarkTeamForDeletion()
  const toast = useToast()

  const handleDeleteTeam = (payload: DeleteTeamPayload) => {
    deleteTeam(payload, {
      onSuccess: () => {
        toast({
          status: 'success',
          title: t`Successfully marked team for deletion`,
        })
        onClose()
      },
      onError: (error) => {
        toast({
          status: 'error',
          title: t`Failed to mark team for deletion. Please try again.`,
          description: error.message,
        })
      },
    })
  }
  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Trans>Delete {teamName}</Trans>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          borderTop="1px solid"
          borderBottom="1px solid"
          py={4}
          borderColor="gray.200"
        >
          <Heading color="gray.800" mb={3}>
            <Trans>The team will no longer be usable.</Trans>
          </Heading>
          <Text color="gray.600">
            <Trans>
              You can no longer assign agents or team leads to this team.
              Conversations involving this team will still be available but will
              be labeled as "Deleted team."
            </Trans>
          </Text>
          <Box
            textAlign="right"
            onClick={() => setToggleDatePicker(!toggleDatePicker)}
            mt={4}
          >
            <Text float="left">
              <Trans>The team will be deleted at: </Trans>
            </Text>
            {i18n.date(date, {
              dateStyle: 'long',
              timeStyle: 'short',
            })}
          </Box>
          <Popover placement="bottom-end">
            {({ onClose }) => (
              <>
                <PopoverTrigger>
                  <Text
                    textAlign="right"
                    color="primary.500"
                    display="inline-block"
                    float="right"
                    fontSize="xs"
                    cursor="pointer"
                    fontWeight="medium"
                  >
                    <Trans>Change</Trans>
                  </Text>
                </PopoverTrigger>
                <PopoverContent width="100%">
                  <span>
                    <PopoverArrow />
                  </span>
                  <PopoverBody px={0} pt={4}>
                    <Flex flexDir="column" alignItems="flex-start" gap={2}>
                      <Box>
                        <DatePicker
                          onSelectDate={(date) => setDate(endOfDay(date))}
                          value={date}
                          disabled={[{ before: new Date() }]}
                        />
                      </Box>
                      <Divider />
                      <Flex
                        justifyContent="flex-end"
                        width="100%"
                        px={4}
                        gap={2}
                      >
                        <Button onClick={onClose}>
                          <Trans>Cancel</Trans>
                        </Button>
                        <Button colorScheme="primary" onClick={onClose}>
                          <Trans>Apply</Trans>
                        </Button>
                      </Flex>
                    </Flex>
                  </PopoverBody>
                </PopoverContent>
              </>
            )}
          </Popover>
        </ModalBody>

        <ModalFooter>
          <Button mr={3} onClick={onClose}>
            <Trans>Cancel</Trans>
          </Button>
          <Button
            _hover={{ bg: 'danger' }}
            backgroundColor="danger"
            color="white"
            variant="ghost"
            onClick={() => handleDeleteTeam({ teamUid: teamUid, when: date })}
          >
            <Trans>That's okay, delete the team</Trans>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export type IntegrationsV2 = 'liveAgent' | 'cognigy-chat' | 'oresundsbron-mail'

export type IntegrationV2 = {
  name: string
  key: IntegrationsV2
  color: string
  configV2: true
  url: string
}

export const textIntegrationsV2: IntegrationV2[] = [
  {
    color: '#59195D',
    key: 'liveAgent',
    name: 'LiveAgent',
    configV2: true,
    url: 'liveagent',
  },
  {
    color: '#59195D',
    key: 'cognigy-chat',
    name: 'cognigy-chat',
    configV2: true,
    url: 'cognigy-chat',
  },
  {
    configV2: true,
    color: '#2980c9',
    url: 'oresundsbron-mail',
    key: 'oresundsbron-mail',
    name: 'OB mail',
  },
]

import { Button, useToast } from '@capturi/ui-components'
import { Box, Flex, SimpleGrid } from '@chakra-ui/react'
import React, { useState } from 'react'
import {
  type ConfigField,
  ContinuationTokenField,
  Field,
} from '../../components/Field'
import {
  TextIntegrationConfigSchema,
  useToggleTextImporter,
  useUpdateTextImporter,
} from '../useConfig'
import CustomFieldSelector from './CustomFieldSelector'

type Props = {
  schema: TextIntegrationConfigSchema
  currentCustomFields: string[]
  currentIntegrationOptions: Record<string, string | string[] | boolean>
  currentFilters: Record<string, string | string[] | boolean>
  currentContinuationToken: string | Date
  integrationType: string
  integrationKey: string
  isRunning: boolean
}

const extractDefaultValues = (fields: ConfigField[]) => {
  return fields.reduce<Record<string, unknown>>((memo, f) => {
    memo[f.key] = f.defaultValue

    return memo
  }, {})
}
export const IntegrationConfig: React.FC<Props> = ({
  schema,
  currentIntegrationOptions,
  currentFilters,
  currentCustomFields,
  currentContinuationToken,
  integrationType,
  integrationKey,
  isRunning,
}) => {
  const toast = useToast()
  const { mutate, isPending } = useUpdateTextImporter(
    integrationType,
    integrationKey,
  )
  const toggle = useToggleTextImporter(integrationType)

  // biome-ignore lint/suspicious/noExplicitAny: could be nice to fix with something smarter
  const [continuationToken, setContinuationToken] = useState<any>(
    currentContinuationToken,
  )

  const [filters, setFilters] = useState<Record<string, unknown>>({
    ...extractDefaultValues(schema.filters),
    ...currentFilters,
  })
  const [options, setOptions] = useState<Record<string, unknown>>({
    ...extractDefaultValues(schema.options),
    ...currentIntegrationOptions,
  })
  const [customFields, setCustomFields] =
    useState<string[]>(currentCustomFields)

  const handleUpdate = () => {
    mutate(
      { customFields, options, filters, continuationToken },
      {
        onSuccess: () => {
          toast({ status: 'success', title: 'Integration Updated' })
        },
        onError: (error) => {
          toast({
            status: 'error',
            title: error.name,
            description: error.message,
          })
        },
      },
    )
  }

  return (
    <div>
      <SimpleGrid columns={2} spacing="6">
        <div>
          <ContinuationTokenField
            value={continuationToken}
            onChange={setContinuationToken}
            type={schema.continuationToken}
            isDisabled={isPending}
          />
          {schema.filters.map((f) => (
            <Box my="3" key={f.key}>
              <Field
                key={f.key}
                field={f}
                onChange={(v) => setFilters((s) => ({ ...s, [f.key]: v }))}
                value={filters[f.key]}
              />
            </Box>
          ))}
        </div>
        <div>
          {schema.options.map((f) => (
            <Box my="3" key={f.key}>
              <Field
                key={f.key}
                field={f}
                onChange={(v) => setOptions((s) => ({ ...s, [f.key]: v }))}
                value={options[f.key]}
              />
            </Box>
          ))}
          {schema.customFields.length > 0 && (
            <Box my="3">
              <CustomFieldSelector
                options={schema.customFields}
                onChange={setCustomFields}
                isDisabled={isPending}
                value={customFields}
              />
            </Box>
          )}
        </div>
      </SimpleGrid>
      <Flex justifyContent="space-between">
        <Flex>
          <Button
            colorScheme="green"
            mx="2"
            isLoading={toggle.isPending}
            onClick={() =>
              toggle.mutate({ uid: integrationKey, action: 'start' })
            }
            isDisabled={isRunning}
          >
            Start
          </Button>
          <Button
            colorScheme="red"
            mx="2"
            isLoading={toggle.isPending}
            onClick={() =>
              toggle.mutate({ uid: integrationKey, action: 'stop' })
            }
            isDisabled={!isRunning}
          >
            Stop
          </Button>
        </Flex>
        <Button primary isLoading={isPending} onClick={handleUpdate}>
          Save
        </Button>
      </Flex>
    </div>
  )
}

import type { ResponseError } from '@capturi/request'
import request from '@capturi/request/src/request'
import { type UseQueryResult, useQuery } from '@tanstack/react-query'
import type { ConfigField } from '../../components/Field'

export const useTextImporterAuthSchema = (
  urlPath: string,
): UseQueryResult<{ fields: ConfigField[] }, ResponseError> =>
  useQuery({
    queryKey: ['importers', 'text', 'schema', 'auth', urlPath],
    queryFn: () => request.get(`importer/${urlPath}/auth-schema`),
  })

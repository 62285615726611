import {
  Alert,
  AlertDescription,
  AlertTitle,
  Button,
  Flex,
  HStack,
  Text,
  useToast,
} from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { Trans, t } from '@lingui/macro'
import React from 'react'
import { MdWarning } from 'react-icons/md'
import { useUnmarkTeamForDeletion } from '../../hooks/useTeams'

type Props = { when: Date; teamUid: string }

const TeamScheduledForDeletion: React.FC<Props> = ({ when, teamUid }) => {
  const { mutate: cancelTeamDeletion } = useUnmarkTeamForDeletion()
  const toast = useToast()

  const handleUnmarkTeamDeletion = async () => {
    cancelTeamDeletion(teamUid, {
      onSuccess: () => {
        toast({
          status: 'success',
          description: t`Successfully cancelled deletion of team`,
        })
      },
      onError: () => {
        toast({
          status: 'error',
          description: t`Something went wrong when cancelling deletion of team. Please try again`,
        })
      },
    })
  }
  return (
    <Alert
      status="warning"
      borderRadius="6px"
      borderLeft="4px solid"
      borderColor="warningDark"
      py={1}
    >
      <HStack w="100%" justify="space-between" align="center">
        <Flex>
          <Flex color="warningDark" mr={3} fontSize="20px">
            <MdWarning />
          </Flex>
          <AlertTitle fontSize="sm" fontWeight="medium">
            Team scheduled for deletion
          </AlertTitle>
        </Flex>
        <Flex flex={1} align="center" justify="space-between">
          <AlertDescription>
            <Text>
              <Trans>Will be deleted</Trans>{' '}
              {i18n.date(when, {
                dateStyle: 'long',
                timeStyle: 'short',
              })}
            </Text>
          </AlertDescription>
          <Button
            variant="ghost"
            _hover={{ backgroundColor: 'none' }}
            fontWeight="medium"
            onClick={handleUnmarkTeamDeletion}
          >
            <Trans>Cancel scheduled deletion</Trans>
          </Button>
        </Flex>
      </HStack>
    </Alert>
  )
}

export default TeamScheduledForDeletion

import React from 'react'
import { useListTextImporter } from '../useConfig'
import { DataLoaderContainer } from './DataLoaderContainer'

export const TextConfigV2: React.FC = () => {
  const { data, isLoading, error } = useListTextImporter('liveagent')
  const cognigyChat = useListTextImporter('cognigy-chat')
  const oresundsbronMail = useListTextImporter('oresundsbron-mail')

  if (isLoading) return null
  if (error) return null

  if (data)
    return (
      <div>
        {data.map((config) => (
          <DataLoaderContainer
            config={config}
            key={config.uid}
            integrationUid={config.uid}
            integrationType="liveAgent"
            url="liveagent"
          />
        ))}
        {(cognigyChat.data || []).map((config) => (
          <DataLoaderContainer
            config={config}
            key={config.uid}
            integrationUid={config.uid}
            integrationType="cognigy-chat"
            url="cognigy-chat"
          />
        ))}
        {(oresundsbronMail.data || []).map((config) => (
          <DataLoaderContainer
            config={config}
            key={config.uid}
            integrationUid={config.uid}
            integrationType="oresundsbron-mail"
            url="oresundsbron-mail"
          />
        ))}
      </div>
    )
}

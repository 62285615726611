import { Team, comparators } from '@capturi/core'
import request from '@capturi/request'
import { useQuery } from '@tanstack/react-query'
import constate from 'constate'
import groupBy from 'lodash/groupBy'
import React from 'react'
import useSWR from 'swr'

import { UserResponse } from '../types'

type Actions = {
  create: (name: string) => Promise<Team>
  update: (uid: string, name: string) => Promise<Team>
  delete: (uid: string) => Promise<void>
}

const URL = 'authentication/organization/teams'

function useTeamsApi(): {
  teams: Team[]
  actions: Actions
} {
  //TODO: cerate get teams-with-users endpoint
  const { data: userDate } = useQuery<{ users: UserResponse[] }>({
    queryKey: ['authentication/users?includeDeleted=false'],
  })
  const { data, mutate } = useSWR<Team[]>(URL)

  const teams = React.useMemo(() => {
    const memberGroups = groupBy(userDate?.users, 'team.uid')
    return (data ?? [])
      .map((team) => {
        team.members = memberGroups[team.uid] ?? []
        return team
      })
      .sort(comparators.compareTeams)
  }, [userDate?.users, data])

  const actions: Actions = React.useMemo(() => {
    return {
      create: async (name) => {
        const resp = await request.post<Team>(URL, { json: { name } })
        mutate((teams) => (teams ? [resp, ...teams] : [resp]))
        return resp
      },
      update: async (uid, name) => {
        const resp = await request.put<Team>(`${URL}/${uid}`, {
          json: { name },
        })
        mutate(teams.map((t) => (t.uid === uid ? { ...t, ...resp } : t)))
        return resp
      },
      delete: async (uid) => {
        await request.delete(`${URL}/${uid}`)
        mutate(teams.filter((t) => !(t.uid === uid)))
      },
    }
  }, [mutate, teams])
  return { teams, actions }
}

const [TeamsApiProvider, useTeamsApiContext] = constate(useTeamsApi)

export { TeamsApiProvider, useTeamsApiContext }
